<template>
  <Card style="width: 30vw">

    <template #content>
      <div class="grid">

        <div class="col-12">
          <Avatar icon="pi pi-user" class="mr-3" size="xlarge" shape="circle" style="width: 5.5rem;height: 5.5rem;background-color:#5297ff; color: #ffffff" />
        </div>

        <div class="col-12">
          <div class="grid" style="margin-left: 7vw">

        <!-- username -->
        <div class="col-3 label">{{ t('label.username') }}</div>
        <div class="col-9 text">{{ user.username }}</div>

        <!-- firstname -->
        <div class="col-3 label">{{ t('label.firstname') }}</div>
        <div class="col-9 text">{{ user.firstname }}</div>

        <!-- lastname -->
        <div class="col-3 label">{{ t('label.lastname') }}</div>
        <div class="col-9 text">{{ user.lastname }}</div>

        <!-- locale -->
        <div class="col-3 label">{{ t('label.locale') }}</div>
        <div class="col-9 text">{{user.locale}}</div>

        <!-- roles
        <div class="p-col-3 label">{{ t('label.ruolo', 2) }}</div>
        <div class="p-col">
            <div v-for="role in user.roles" :key="role">
              <span class="text">{{ role }}</span>
            </div>
        </div>-->

          </div>
        </div>

      </div>
    </template>
  </Card>

</template>

<script setup>
import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import {useI18n} from "vue-i18n";

const store = useStore();
const {t} = useI18n();

const user = computed(() => store.getters['auth/loggedUser']);

onMounted(async () => {
 await store.dispatch("auth/fetchUser");
});

</script>

<style lang="scss" scoped>

$fontSize: 1.1rem;

.text{
  font-size: $fontSize;
}

.label {
  font-weight: bold;
  font-size: $fontSize;
}

</style>
